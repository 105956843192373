import React, { useState, useEffect } from "react";
//import "./styles.css";
import { Row, Col, Button, Modal, Form } from "react-bootstrap";
import LeftpanelMember from "../../../components/leftpanel/leftpanel-member";
import HeaderPart from "../../../components/layout/header/header";
import LoadingOverlay from "react-loading-overlay";


import {
    tvi_star_high_level,
    tvi_star_media,
    AccessDeniedError
} from "../../../assets/images";

const TviStarDashboardPart = () => {
    return (
        <div>
            <HeaderPart />
            <div className="main_cont">
                <LeftpanelMember />
                <div className="right_cont">
                    <div className="right_banner_banner_cnt">
                        {(localStorage.getItem('subPrice') && localStorage.getItem('subPrice') == '1997' || localStorage.getItem('subPrice') == '4997') ? (<>
                            <Row>
                                <Col lg="4" md="6" sm="12" className="right_innrBanner">
                                    <div className="right_inner_inner_banner">
                                        <a href="https://calendly.com/ali-tvi/30min" target="_blank" className="banner2_box travel_boxxx">
                                            <img src={tvi_star_media} className="img-fluid" />
                                            {/* <div className="banner1_overlay">Shareable Images</div> */}
                                        </a>
                                    </div>
                                </Col>

                                <Col lg="4" md="6" sm="12" className="right_innrBanner">
                                    <div className="right_inner_inner_banner">
                                        <a href="https://solutionswithjkn.com/hl-account" target="_blank" className="banner2_box travel_boxxx">
                                            <img src={tvi_star_high_level} className="img-fluid" />
                                            {/* <div className="banner1_overlay">Certificate Guide</div> */}
                                        </a>
                                    </div>
                                </Col>

                            </Row>
                        </>) : (<>
                            <Row>
                                <Col lg="12" md="6" sm="12" className="right_innrBanner text-center">
                                    <img src={AccessDeniedError} alt="" />
                                </Col>
                            </Row>
                        </>)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TviStarDashboardPart;