import Home_Img from "./home.png";
import Plane_Img from "./plane-icon.png";
import Shopping_Img from "./shopping-cart.png";
import User_Img from "./user-icon.png";
import Dollar_Img from "./dollar-icon.png";
import Calendar_Img from "./calendar-icon.png";
import Palm_Img from "./palm-icon.png";
import Relation_Img from "./relationship-icon.png";
import Logo from "./logo.png";
import Profile_Icon from "./user-profile-icon.png";
import Banner_1 from "./banner_1.png";
import Shopping from "./shopping.jpg";
import MyAccntBanner from "./signup-banner.jpg";
import ReferFriend from "./refer-a-friend.jpg";
import CErtificates from "./certificate.png";
import PlayIcon from "./play.png";
import Resources from "./resources.png";
import Partners from "./friend.png";
import Certificatebanner from "./certificate-banner.png";
import TrainingVideos from "./training-videos.png";
import ResourcesBanner from "./resources-banner.png";
import PrefferedPartners from "./preferred-partners.png";
import AffiliateDashboard from "./affiliate-dashboard.png";
import UserIcon from "./user.png";
import Email from "./email.png";
import Complimentary from "./complimentary.jpg";
import EmailInv from "./email_inv.png";
import NightMexico from "./5_night_mexico.png";
import Night7Mexico from "./7_night_mexico.png";
import Destination3500 from "./3500_destination.png";
import Dream7Night from "./7_night_dream.png";
import AllInclusive from "./all_inclusive.png";

import AddIcon from "./add_icon.png";
import DeleteIcon from "./delete.png";
import AddLink from "./add-link.png";
import CertDet from "./cert-det.png";
import UserDet from "./user-det.png";
import TransDet from "./trans-det.png";
import CertDet2 from "./cert-detils.png";
import TviWLogo from "./tvi_white_logo.png";
import Facebook from "./facebook.png";
import Google from "./google.png";
import EmailIcon from "./email_icon.png";
import MemberIcon from "./member.png";

import HeaderTwoLogo from "./ttripvalet-logo.png";
import LoginBanner from "./login_banner.jpg";
import Trip from "./trip.jpg";
import PDFFile from "./3500_destination.pdf";
import PaymntSuccess from "./paymnt-success.png";
import PaymntFailed from "./paymnt-failed.png";
import Logout from "./logout.png";
import DownArrow from "./down-arrow.png";
import ClickImg from "./click.png";
import CommissionsImg from "./discount.png";
import ImpressionsImg from "./impressions.png";
import RefundImg from "./refund.png";
import Dollar from "./dollar.png";
import Redeem from "./redeem.png";
import logo_image from "./logo_image.jpg";

import BannerImg from "./banner_img.png";
import Lead1 from "./lead_1.png";
import Lead2 from "./lead_2.png";
import Lead3 from "./lead_3.png";
import brand_logo_two from "./brand_logo_two.png";
import Test1 from "./test_1.png";
import Test2 from "./test_2.png";
import Test3 from "./test_3.png";
import Test4 from "./test_4.png";
import Test5 from "./test_5.png";
import Test6 from "./test_6.png";
import Test7 from "./test_7.png";
import Test8 from "./test_8.webp";
import Test9 from "./test_9.png";
import Test10 from "./test_10.webp";
import Test11 from "./test_11.webp";
import Test12 from "./test_12.webp";
import Test13 from "./test_13.webp";
import Test14 from "./test_14.webp";
import Test15 from "./test_15.png";
import Test16 from "./test_16.webp";
import Test17 from "./test_17.webp";
import Test18 from "./test_18.png";
import Test19 from "./test_19.png";
import Test20 from "./test_20.webp";
import Test21 from "./test_21.webp";
import Test22 from "./test_22.webp";
import Bulb from "./bulb.png";
import Pyramid from "./pyramid.png";
import Globe from "./globe.png";
import DOller from "./doller.png";
import Smile from "./smile.png";
import Starter from "./starter.png";
import Enterprenour from "./enterprenour.png";
import Advanced from "./advanced.png";
import CertificateImg from "./certificate_img.png";
import FooterLogo from "./footer_logo.png";

import EmailBanner from "./email-banner.jpg";
import PrintBg from "./print-banner.jpg";
import EmbedBanner from "./embed_code_banner.jpg";
import GettingStarted from "./getting_started.jpg";
import Insurance from "./insurance.jpg";
import RealAgents from "./real_estate_agents.jpg";
import WixTemplate from "./wix_templates.jpg";
import ShareableImages from "./sharable_images.jpg";
import CertificateGuide from "./certificate_guide.jpg";
import ShareContents from "./share_contents.jpg";
import FaqBanner from "./faqs.jpg";
import TViAcademy from "./tvi_academy.jpg";
import FacebookEssentials from "./facebook_bg.jpg";
import InstagramEssentials from "./instagram_essentials.jpg";
import LinkedinEssentials from "./linkedin_essentials.jpg";
import ConfidenceEssentials from "./confidence_essentials.jpg";
import PsychologyMarketing from "./psychology_marketing.jpg";
import PUblicSpeaking from "./public_speaking.jpg";
import SalesEssentials from "./sales_essentials.jpg";
import NegotiationPrinciples from "./negotiation_principles.jpg";
import copyIcon from "./copy.png";

import NextLevelAgent from "./next-level-agent.jpg";
import NextLevelRealtor from "./next-level-realtor.jpg";
import backImage from "./back.png";

import incentive_ebook from "./incentive_ebook.webp";
import sales_bg from "./sales_bg.webp";
import ittyb_bg from "./ittyb_bg.webp";
import more_leads from "./more_leads.svg";
import more_sales from "./more_sales.svg";
import more_referarals from "./more_referarals.svg";
import repeat_business from "./repeat_business.svg";
import gallery_one from "./gallery_one.jpg";
import gallery_two from "./gallery_two.jpg";
import gallery_three from "./gallery_three.webp";
import gallery_four from "./gallery_four.jpg";
import gallery_five from "./gallery_five.webp";
import gallery_six from "./gallery_six.webp";
import gi_01 from "./gi_01.webp";
import gi_02 from "./gi_02.webp";
import gi_03 from "./gi_03.webp";
import gi_04 from "./gi_04.webp";
import gi_05 from "./gi_05.webp";
import pdf from "./pdf.svg";
import star from "./star.svg";
import share from "./star.svg";
import imagine from "./imagine.webp";
import Test23 from "./test_23.webp";
import Test24 from "./test_24.webp";
import Test25 from "./test_25.webp";
import Test26 from "./test_26.webp";
import Test27 from "./test_27.webp";
import Test28 from "./test_28.webp";
import Test29 from "./test_29.webp";
import Test30 from "./test_30.webp";
import Test31 from "./test_31.webp";
import reale_state from "./reale_state.svg";
import insurance from "./insurance.svg";
import small_business from "./small_business.svg";
import digital_marketing from "./digital_marketing.svg";
import sales_professionals from "./sales_professionals.svg";
import marketing_image_lead from "./marketing_image_lead.webp";
import tripvalet from "./tripvalet.webp";
import affiliate_banner from "./affiliate_banner.webp";
import commission_circle from "./commission_circle.webp";
import memberships_chart_list from "./memberships_chart_list.webp";
import tripvalet_affiliate_logo from "./tripvalet_affiliate_logo.webp";
import realestate_banner from "./realestate_banner.webp";
import incentives_bg from "./incentives_bg.webp";
import vacation_incentives from "./vacation_incentives.webp";
import sec_alt_img_one from "./sec_alt_img_one.webp";
import sec_alt_img_two from "./sec_alt_img_two.webp";
import sec_alt_img_three from "./sec_alt_img_three.webp";
import sec_alt_img_four from "./sec_alt_img_four.webp";
import insurance_banner from "./insurance_banner.webp";
import insurance_Incentives_bg from "./insurance_Incentives_bg.webp";
import digital_market_banner from "./digital_market_banner.webp";
import digital_market_incentive from "./digital_market_incentive.webp";
import small_business_incentive from "./small_business_incentive.webp";
import small_business_banner from "./small_business_banner.webp";
import sales_professionals_incentive from "./sales_professionals_incentive.webp";
import sales_professionals_banner from "./sales_professionals_banner.webp";
import commingSoon from "./COMING_SOON.png";
import COMING_SOON_all_inclusive from "./COMING_SOON_all_inclusive.png";
import COMING_SOON_cruise from "./COMING_SOON_cruise.png";
import inclusive_total_image from "./inclusive_total_image.jpg";
import cruise_total_image from "./cruise_total_image.jpg";
import tvi_star_image from "./tvi_star_image.png";
import tvi_star_high_level from "./tvi_star_high_level.png";
import tvi_star_media from "./tvi_star_media.png";
import AccessDeniedError from "./AccessDeniedError.png";

export {
  Home_Img,
  Plane_Img,
  Shopping_Img,
  User_Img,
  Dollar_Img,
  Calendar_Img,
  Palm_Img,
  Relation_Img,
  Logo,
  Profile_Icon,
  Banner_1,
  Shopping,
  MyAccntBanner,
  ReferFriend,
  CErtificates,
  PlayIcon,
  Resources,
  Partners,
  Certificatebanner,
  TrainingVideos,
  ResourcesBanner,
  PrefferedPartners,
  AffiliateDashboard,
  UserIcon,
  Email,
  Complimentary,
  EmailInv,
  NightMexico,
  Night7Mexico,
  Destination3500,
  Dream7Night,
  AllInclusive,
  AddIcon,
  DeleteIcon,
  AddLink,
  CertDet,
  UserDet,
  TransDet,
  CertDet2,
  TviWLogo,
  Facebook,
  Google,
  EmailIcon,
  MemberIcon,
  HeaderTwoLogo,
  LoginBanner,
  Trip,
  PDFFile,
  PaymntSuccess,
  PaymntFailed,
  Logout,
  DownArrow,
  ClickImg,
  CommissionsImg,
  ImpressionsImg,
  RefundImg,
  Dollar,
  Redeem,
  logo_image,
  BannerImg,
  Lead1,
  Lead2,
  Lead3,
  brand_logo_two,
  Test1,
  Test2,
  Test3,
  Test4,
  Test5,
  Test6,
  Test7,
  Test8,
  Test9,
  Test10,
  Test11,
  Test12,
  Test13,
  Test14,
  Test15,
  Test16,
  Test17,
  Test18,
  Test19,
  Test20,
  Test21,
  Test22,
  Bulb,
  Pyramid,
  Globe,
  DOller,
  Smile,
  Starter,
  Enterprenour,
  Advanced,
  CertificateImg,
  FooterLogo,
  EmailBanner,
  PrintBg,
  EmbedBanner,
  GettingStarted,
  Insurance,
  RealAgents,
  WixTemplate,
  ShareableImages,
  CertificateGuide,
  ShareContents,
  FaqBanner,
  TViAcademy,
  FacebookEssentials,
  InstagramEssentials,
  LinkedinEssentials,
  ConfidenceEssentials,
  PsychologyMarketing,
  PUblicSpeaking,
  SalesEssentials,
  NegotiationPrinciples,
  copyIcon,
  NextLevelAgent,
  NextLevelRealtor,
  backImage,
  incentive_ebook,
  sales_bg,
  ittyb_bg,
  more_leads,
  more_sales,
  more_referarals,
  repeat_business,
  gallery_one,
  gallery_two,
  gallery_three,
  gallery_four,
  gallery_five,
  gallery_six,
  gi_01,
  gi_02,
  gi_03,
  gi_04,
  gi_05,
  pdf,
  star,
  share,
  imagine,
  Test23,
  Test24,
  Test25,
  Test26,
  Test27,
  Test28,
  Test29,
  Test30,
  Test31,
  reale_state,
  insurance,
  small_business,
  digital_marketing,
  sales_professionals,
  marketing_image_lead,
  tripvalet,
  affiliate_banner,
  commission_circle,
  memberships_chart_list,
  tripvalet_affiliate_logo,
  realestate_banner,
  incentives_bg,
  vacation_incentives,
  sec_alt_img_one,
  sec_alt_img_two,
  sec_alt_img_three,
  sec_alt_img_four,
  insurance_banner,
  insurance_Incentives_bg,
  digital_market_banner,
  digital_market_incentive,
  small_business_incentive,
  small_business_banner,
  sales_professionals_incentive,
  sales_professionals_banner,
  commingSoon,
  COMING_SOON_all_inclusive,
  COMING_SOON_cruise,
  inclusive_total_image,
  cruise_total_image,
  tvi_star_image,
  tvi_star_high_level,
  tvi_star_media,
  AccessDeniedError
};
